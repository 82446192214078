import React from "react";
import { Modal } from "react-bootstrap";

export default function Dialog(props) {
  const { children, footer, onClose, show, size, title, closeButton = true, bodyProps, className } = props;

  return (
    <Modal
      aria-hidden={true}
      size={size ? size : "lg"}
      backdrop="static"
      keyboard={true}
      show={show}
      onHide={onClose}
      className={className}
    >
      <Modal.Header bsPrefix={!closeButton ? "no-close-header" : ""} closeButton={closeButton}>
        <Modal.Title className="h6">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body {...bodyProps}>{children}</Modal.Body>

      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
}
